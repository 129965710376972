.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 40px 20px;
  background-color: #fff;
  color: #000;
}

.contact-container h1 {
  margin-bottom: 20px;
}

.contact-container p {
  margin-bottom: 10px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.contact-text{
    font-family: 'Montserrat', sans-serif;
}

.contact-email {
  color: #000;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.contact-email:hover {
  color: #cccccc;
}
