/* OptionsRow.css */

.options-row {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 0 10px; /* Mimics horizontal padding in the content container */
  gap: 10px;
}

.option-container {
  margin: 10px;
}
