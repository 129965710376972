* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Hide Scrollbar */
.sections-wrapper {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.sections-wrapper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Body Font */
body {
  font-family: 'Montserrat', sans-serif;
  color: #000;
}

/* Container */
.landing-container {
  overflow-x: hidden;
}

/* Header */
.landing-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #000;
}

.header-logo {
  width: 80px;
  height: auto;
}

.header-middle {
  flex-grow: 1;
  text-align: center;
}

.header-middle-text {
  color: #ffffff;
  font-size: 1.2em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.header-buttons {
  display: flex;
  align-items: center;
}

/* Sections Wrapper */
.sections-wrapper {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  scroll-behavior: smooth;
}

/* Landing Section */
.landing-section {
  scroll-snap-align: start;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 80px; /* Header height */
}

.landing-footer-section {
  min-height: 40vh;
}

/* Section Content */
.section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
}

.image-left .image-content {
  order: 1;
}

.image-left .text-content {
  order: 2;
}

.image-right .image-content {
  order: 2;
}

.image-right .text-content {
  order: 1;
}

/* Adjust text-content alignment based on image placement */
.image-left .text-content {
  text-align: left;
  align-items: flex-start;
}

.image-right .text-content {
  text-align: right;
  align-items: flex-end;
}

.text-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.section-subtitle {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.download-container {
  display: flex;
  flex-direction: column;
}

/* Adjust download-container alignment based on image placement */
.image-left .download-container {
  align-items: flex-start;
}

.image-right .download-container {
  align-items: flex-end;
}

.download-text {
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: 600;
}

.qr-code-image {
  width: 170px;
  height: auto;
  border: 2px solid;
  border-radius: 15px;
}

.qr-code-image.light {
  border-color: #000000;
}

.qr-code-image.dark {
  border-color: #ffffff;
}

.badges-row {
  display: flex;
  gap: 20px;
  align-items: center;
}

.store-badge {
  height: 50px;
  width: auto;
  cursor: pointer;
  transition: transform 0.2s;
}

.store-badge:hover {
  transform: scale(1.05);
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.section-image {
  width: 50%;
  max-width: 400px;
  height: auto;
}

/* Scroll Button */
.scroll-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  color: inherit;
  display: flex;
  align-items: center;
}

.scroll-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-button-text {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0 5px;
  border-radius: 50px;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  background-color: rgba(128, 128, 128, 0.2);
  font-weight: 700;
  font-size: 1.2em;
}

.scroll-button-icon {
  margin-right: 5px;
  font-size: 1.5em;
  transition: transform 0.2s;
}

.scroll-button:hover .scroll-button-icon {
  transform: scale(1.1);
}

.scroll-button:hover .scroll-button-text {
  background-color: rgba(128, 128, 128, 0.3);
}

/* Trusted and Footer Container */

.trusted-section {
  padding: 50px 20px;
  text-align: center;
  background-color: #f0f0f0; /* Updated background color */
}

.trusted-title {
  font-size: 2em;
  color: #000000; /* Changed text color to black for contrast */
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
}

.logo-carousel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.trusted-logo {
  height: 50px;
  width: auto;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.trusted-logo:hover {
  opacity: 1;
}

/* Footer */
.landing-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #000000; /* Changed text color to black for contrast */
  font-family: 'Montserrat', sans-serif;
}

.footer-left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-link {
  color: #000000; /* Changed text color to black for contrast */
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  font-size: 14px;
  position: relative;
}

.footer-link::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  color: purple; /* Changed text color to black for contrast */
  font-family: 'Montserrat', sans-serif;
  left: 0;
  bottom: -2px;
  transition: width 0.3s;
}

.footer-link:hover::after {
  width: 100%;
}

.footer-right {
  display: flex;
  align-items: center;
}

.instagram-link {
  color: #000000;
  font-size: 1.5em;
  transition: color 0.3s;
}

.instagram-icon {
  width: 30px;
  height: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-content {
    flex-direction: column;
  }

  .text-content,
  .image-content {
    padding: 10px;
    align-items: center;
    text-align: center;
  }

  /* Override alignment on small screens */
  .image-left .text-content,
  .image-right .text-content {
    align-items: center;
    text-align: center;
  }

  .image-left .download-container,
  .image-right .download-container {
    align-items: center;
  }

  .section-title {
    font-size: 2em;
  }

  .section-subtitle {
    font-size: 1.2em;
  }

  .landing-footer {
    flex-direction: column;
    gap: 10px;
  }

  .footer-left {
    flex-direction: column;
    gap: 5px;
  }

  .footer-right {
    justify-content: center;
  }
}
