.app-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f7f7f7;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.app-banner-links img {
  height: 30px;
  margin-right: 10px;
}

.dismiss-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
