.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

.modal-content {
  background-color: #fff; /* Replace with theme.colors.background */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 3.5px rgba(0, 0, 0, 0.25);
  width: 85%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333; /* Replace with theme.colors.onBackground */
  font-size: 18px;
  text-align: center;
}

.modal-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  padding-top: 10px;
  color: #333; /* Replace with theme.colors.onBackground */
  font-size: 16px;
  text-align: center;
}

.button-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.button-single {
  margin-top: 20px;
}
