.button-general {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none;
  border: none;
  flex-direction: row;
}

.button-general:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  text-align: center;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.button-icon {
  margin-right: 5px;
}

.button-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 10px;
  height: 10px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
