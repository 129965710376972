.terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #fff;
  color: #000;
}

.terms-container h1 {
  margin-bottom: 20px;
}

.terms-text {
  font-family: 'Montserrat', sans-serif;
}

.terms-content {
  max-width: 800px;
  width: 100%;
  overflow-y: auto;
  max-height: 80vh;
  padding: 20px;
  background-color: #e1e1e1;
  border-radius: 8px;
}

.terms-content p {
  margin-bottom: 16px;
  line-height: 1.6;
}
