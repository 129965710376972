.listing-container {
  border-radius: 10px;
  overflow: hidden;
  margin: 5px;
  width: 180px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.listing-container:hover {
  transform: scale(1.02);
}

.listing-image-container {
  width: 100%;
  height: 160px;
}

.listing-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* similar to React Native's 'cover' resizeMode */
}

.listing-footer {
  padding: 5px;
  height: 80px;
}

.listing-price {
  font-size: 14px; /* You might adjust this for different platforms */
  font-weight: 600; /* Maps to your 'Semi' font-family */
  color: #000; /* Fallback for theme.colors.onBackground */
  padding-right: 4px;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.listing-name {
  font-size: 12px; /* Fallback for your Platform-specific sizing */
  color: #000; /* Fallback for theme.colors.onBackground */
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
}

.on-sale-badge {
  background-color: #34c759; /* Fallback for theme.colors.onPieGreenContainer */
  padding: 2px 5px;
  border-radius: 3px;
  margin-top: 5px;
  display: inline-block;
}

.on-sale-text-badge {
  color: #fff; /* Fallback for theme.colors.background */
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
