.masonry-listings-container {
  padding: 10px 0;
  overflow-y: auto;
  /* Remove max-height if you want it to expand naturally */
}

.masonry-listings {
  display: grid;
  gap: 10px;
}

/* On very small devices, use 2 columns */
@media (max-width: 480px) {
  .masonry-listings {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Small tablets: 3 columns */
@media (min-width: 481px) and (max-width: 768px) {
  .masonry-listings {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Laptops: 4 columns */
@media (min-width: 769px) and (max-width: 1024px) {
  .masonry-listings {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Desktops: up to 5 columns */
@media (min-width: 1025px) {
  .masonry-listings {
    grid-template-columns: repeat(5, 1fr);
  }
}

.refresh-button {
  margin-bottom: 10px;
  padding: 8px 16px;
  background-color: #007aff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.refresh-button:disabled {
  background-color: #aaa;
}

.list-footer {
  margin-top: 10px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.empty-message {
  text-align: center;
  color: #555;
  font-family: 'Montserrat', sans-serif;
}
