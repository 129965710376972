.all-set-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f5f5f5; /* Light background color */
}

.all-set-content {
  background-color: #ffffff; /* White background for the content box */
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.all-set-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333333;
  font-family: 'Montserrat', sans-serif;
}

.all-set-message {
  font-size: 1.1em;
  margin-bottom: 30px;
  color: #555555;
  font-family: 'Montserrat', sans-serif;
}
