/* OptionCard.css */

/* Define some CSS variables for theme colors. Adjust these as needed or override them dynamically */
:root {
  --disabled-color: #ccc; /* Replace with theme.colors.disabled */
  --on-background-color: #000; /* Replace with theme.colors.onBackground */
}

/* Card container mimics the React Native styles */
.option-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 15px;
  border: 1px solid var(--disabled-color);
  height: 80px;
  min-width: 85px;
  max-width: 200px;
  cursor: pointer;
  transition: border-color 0.3s;
}

/* When selected, use a thicker border and different color */
.option-card.selected {
  border: 2px solid var(--on-background-color);
}

/* Price container styles */
.price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Option name styling */
.option-name {
  font-family: 'Med', sans-serif;
  font-size: 14px;
  text-align: center;
  margin-bottom: 4px;
  flex: 1;
  color: var(--on-background-color);
}

/* When there is no price */
.no-price-text {
  font-family: 'Semi', sans-serif;
  font-size: 14px;
  text-align: center;
  margin-bottom: 4px;
  flex: 1;
  color: var(--on-background-color);
}

/* Original price styling (crossed out) */
.original-price {
  font-family: 'Reg', sans-serif;
  font-size: 14px;
  color: var(--disabled-color);
  text-decoration: line-through;
  padding-left: 3px;
}

/* Final price styling */
.final-price {
  font-family: 'Semi', sans-serif;
  font-size: 16px;
  color: var(--on-background-color);
}

/* Single price styling (when no discount applies) */
.single-price {
  font-family: 'Semi', sans-serif;
  font-size: 16px;
  text-align: center;
  color: var(--on-background-color);
}
