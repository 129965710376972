.see-more-text-container {
  position: relative;
}

.see-more-text {
  margin: 0;
  font-size: 14px; /* Adjust as needed */
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

/* Clamp the text to 4 lines when not expanded */
.clamped {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  font-family: 'Montserrat', sans-serif;
  display: block; /* Makes the button behave like a block element */
  text-align: left; /* Aligns the text inside the button to the left */
}
