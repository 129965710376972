.delete-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  background-color: #fff;
  color: #000;
  font-family: 'Montserrat', sans-serif;
}

.delete-account-container h1 {
  margin-bottom: 20px;
  text-align: center;
}

.delete-account-container p {
  margin-bottom: 16px;
  line-height: 1.6;
}

.delete-account-note {
  font-style: italic;
  color: #333;
}

.delete-account-container ol {
  margin-left: 20px;
  margin-bottom: 16px;
  line-height: 1.6;
}

.delete-account-container li {
  margin-bottom: 8px;
}
