:root {
  /* You can define CSS variables for your theme colors here */
  --on-background: #000;
  --background: #f0f0f0;
  --error: red;
  /* Add more theme variables as needed */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
}

.scrollContainer {
  overflow-y: auto;
  padding-bottom: 20px;
}

.carouselContainer {
  margin-bottom: 20px;
}

.separator {
  height: 5px;
  width: 100%;
  background-color: var(--background);
  margin: 10px 0;
}

.section {
  padding: 0 10px;
  margin-bottom: 20px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: var(--on-background);
  margin: 10px 0;
  text-align: left;
}

.subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--on-background);
  margin-bottom: 10px;
  text-align: left;
}

.address-row {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust spacing between the icon and address text */
  margin: 15px 0px; /* Optional: spacing around the row */
}

.address-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: var(--on-background);
  margin: 0; /* Remove default paragraph margin */
}

.subTitleContainer {
  padding: 15px 0;
}

.moreRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  flex: 1;
  cursor: pointer;
}

.sellerInfoContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.priceRow {
  display: flex;
  align-items: center;
}

.noPriceText {
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: italic;
  color: var(--on-background);
}

.finalPrice {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 26px;
  margin-right: 5px;
  color: var(--on-background);
}

.originalPrice {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-decoration: line-through;
  margin-right: 5px;
  color: var(--on-background);
}

.discountText {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: var(--error);
}

.listingImage {
  height: 70px;
  width: 70px;
  border-radius: 10px;
}

.hoursColumn {
  padding: 10px 0;
}

.hourRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
}

.dayText {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: var(--on-background);
}

.timeText {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: var(--on-background);
}

.lottieImage {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd; /* placeholder background */
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  font-size: 24px;
}

.no-data-text {
  font-family: 'Montserrat', sans-serif;
  color: var(--on-background);
}

.badges-row {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-top: 10px;
}
.store-badge {
  height: 50px;
  width: auto;
  cursor: pointer;
  transition: transform 0.2s;
}
