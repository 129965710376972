/* SellerStore.css */

/* Base container: full width with some padding. */
.container {
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

/* Optionally limit the width on very large screens */
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

/* Default vertical stacking */
.seller-store {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Profile header styling */
.profile-header {
  padding: 15px;
  border-radius: 10px;
  width: 100%;
}

/* Listings section styling */
.profile-listings {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}

/* Seller Row & Info */
.seller-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Make the seller row stack vertically on smaller screens */
@media (max-width: 768px) {
  .seller-row {
    flex-direction: column;
    align-items: center;
  }
  .avatar {
    margin-bottom: 10px;
  }
  .seller-info {
    text-align: center;
  }
}

.avatar {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.seller-info {
  flex: 1;
}

.item-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.item-row svg {
  display: block;
}

.title {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.seller-name {
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Montserrat', sans-serif;
}

.category-text {
  font-size: 14px;
  margin-left: 5px;
  font-family: 'Montserrat', sans-serif;
}

.address-text {
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

/* Description styling */
.description-container {
  padding: 15px 0;
}

/* Button group styling */
.button-group {
  flex: 1;
}

.button-container {
  display: flex;
}

/* Tabs styling */
.tabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.tab-text {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

/* Content container */
.content-container {
  margin: 20px 0;
}

/* Hours modal content */
.hours-modal-content {
  width: 100%;
}

.hours-column {
  padding: 10px 0;
}

.hour-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
}

.day-text {
  font-size: 16px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
}

.time-text {
  font-size: 14px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
}

/* Generic button styles */
button {
  cursor: pointer;
}
