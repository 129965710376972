/* ModernCarousel.css */

.modern-carousel {
  width: 100%;
  position: relative;
  font-family: sans-serif;
}

.modern-carousel-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.modern-carousel-tab {
  display: flex;
  align-items: center;
  margin: 0 12px;
  cursor: pointer;
  color: #ccc;
  font-size: 16px;
  transition: color 0.3s;
}

.modern-carousel-tab.active {
  color: #000;
  font-weight: bold;
}

.modern-carousel-tab span {
  margin-left: 4px;
}

.modern-carousel-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust height as needed */
  overflow: hidden;
}

.modern-carousel-track {
  display: flex;
  transition: transform 0.5s ease;
  height: 100%;
}

.modern-carousel-slide {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.modern-carousel-image,
.modern-carousel-video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.modern-carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
}

.modern-carousel-arrow.left {
  left: 16px;
}

.modern-carousel-arrow.right {
  right: 16px;
}

.modern-carousel-arrow:hover {
  background: rgba(0, 0, 0, 0.7);
}

.modern-carousel-pagination {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.modern-carousel-dot {
  height: 10px;
  width: 10px;
  margin: 0 4px;
  border-radius: 50%;
  background: #ccc;
  transition: background 0.3s, transform 0.3s;
}

.modern-carousel-dot.active {
  background: #000;
  transform: scale(1.2);
}
